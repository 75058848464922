import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {Bird, Subscription} from './types';
import {DeckService, LaunchReminderService} from './services';

@Injectable()
export class AppSandbox {

  constructor(private deckService: DeckService,
              private launchReminderService: LaunchReminderService) {
  }

  loadAllBirds(): Observable<Bird[]> {
    return this.deckService.getAllBirds();
  }

  registerSubscriber(subscriberData: Subscription) {
    return this.launchReminderService.setSubscriber(subscriberData);
  }
}
