import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {applyOnKeys} from '../../tools/object.tools';
import {camelToSnake} from '../../tools/string.tools';
import {Url} from 'url';
import {Subscription} from '../../types/subscription.interface';
import {finalize, map} from 'rxjs/operators';
import {BehaviorSubject} from 'rxjs';
import {NgxSpinnerService} from 'ngx-spinner';

@Injectable()
export class LaunchReminderService {
  private _apiUrl: Url = '/api/launch-subscribe';

  constructor(private httpClient: HttpClient,
              private spinner: NgxSpinnerService) {
  }

  setSubscriber(subscriberData: Subscription) {
    this.spinner.show();
    const tmp = applyOnKeys(subscriberData, camelToSnake);

    return this.httpClient.post(
      `${this._apiUrl}`,
      tmp
    ).pipe(
      map((answer: any) => answer.insertion),
      finalize(() => this.spinner.hide())
    );
  }
}
