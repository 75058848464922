import {ChangeDetectionStrategy, Component} from '@angular/core';

@Component({
  selector: 'amk-root',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./app.container.scss'],
  template: `
    <router-outlet></router-outlet>
  `
})
export class AppContainer {
}
