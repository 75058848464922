import {ChangeDetectionStrategy, Component} from '@angular/core';
import {AppSandbox} from '../../app.sandbox';
import {Observable} from 'rxjs';
import {Bird} from '../../types';

@Component({
  selector: 'amk-deck',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./deck.container.scss'],
  template: `
    <amk-card *ngFor="let bird of deckBirds$ | async"
              [bird]="bird"></amk-card>
  `
})
export class DeckContainer {
  deckBirds$: Observable<Bird[]> = this.appSandbox.loadAllBirds();

  constructor(private appSandbox: AppSandbox) {
  }
}
