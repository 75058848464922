import {ChangeDetectionStrategy, Component} from '@angular/core';
import {Subscription} from '../../types/subscription.interface';
import {AppSandbox} from '../../app.sandbox';

@Component({
  selector: 'amk-tmp-welcome',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./tmp-welcome.container.scss'],
  template: `
    <section>
      <img src="./../../../../assets/logo.png" alt="breedem logo">
    </section>
    <h1>Welcome to Breed'Em</h1>
    <p class="description">The application you need for your bird breeding</p>
    <p class="sub-description">
      No more need for your notebooks, here you can save all your birds data, new borns,
      new acquisitions or just birds you're keeping for some time.
    </p>

    <h3>If you want to be among the first users, or the first breedemers???</h3>
    <p class="form-filling">
      Fill in the following form with your email,
      and we'll text you back when the application is available
    </p>

    <amk-subscription-form (formData)="onSubmit($event)"></amk-subscription-form>

    <ngx-spinner bdColor="rgb(200,240,240)"
                 size="large"
                 color="#54bbba"
                 type="ball-spin"
                 [fullScreen]="true"></ngx-spinner>
  `
})
export class TmpWelcomeContainer {

  constructor(private sb: AppSandbox) {
  }

  onSubmit(subscriptionData: Subscription): void {
    this.sb.registerSubscriber(subscriptionData).subscribe(
      () => alert(
        `Thanks ${subscriptionData.firstName} for subscription. You'll receive an email once we launch Breedem services 🐦😉`
      ),
      () => alert('SubscriptionInterface failed, try again please...')
    );
  }
}
