import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {Bird} from "../../types";
import {DEFAULT_BIRD_AVATAR, getAge} from "../../tools";

@Component({
  selector: 'amk-card',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./card.component.scss'],
  template: `
    <mat-card>
      <mat-card-header>
        <mat-card-title *ngIf="bird?.name">{{ bird.name }}</mat-card-title>
        <mat-card-subtitle *ngIf="bird?.colorOrMutation">
          {{ bird?.colorOrMutation.join(', ') }}
        </mat-card-subtitle>
      </mat-card-header>
      <img *ngIf="bird?.pictures; else defaultPicture"
           mat-card-image
           src="{{ bird.pictures[0] }}"
           alt="Photo of the bird">
      <ng-template #defaultPicture>
        <img src="{{ defaultAvatar }}" alt="Default bird avatar">
      </ng-template>
      <mat-card-content>
        <p *ngIf="bird?.sex">
          Sex: {{ bird.sex === true ? 'Hen' : 'Cock' }}.
          <span *ngIf="bird?.band.banded">Official band.</span>
          <span *ngIf="bird?.age">{{ getAge(bird.age) }}</span>
        </p>
        <p *ngIf="bird?.species">Species: {{ bird.species }} / {{ bird.subspecies || 'Not a subspecies' }}</p>
      </mat-card-content>
    </mat-card>
  `
})
export class CardComponent {
  @Input() bird: Partial<Bird>;

  defaultAvatar = DEFAULT_BIRD_AVATAR;
  getAge = getAge;
}
