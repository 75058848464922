import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {applyOnKeys, snakeToCamel} from '../../tools';
import {Bird} from '../../types';

@Injectable()
export class DeckService {
  private apiUrl = '/api/deck';

  constructor(private http: HttpClient) {
  }

  getAllBirds(): Observable<Bird[]> {
    return this.http.get<{birds: Bird[]}>(`${this.apiUrl}`)
      .pipe(
        map(result => applyOnKeys(result.birds, snakeToCamel))
      );
  }
}
