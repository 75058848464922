import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {TmpWelcomeContainer} from './containers/tmp-welcome/tmp-welcome.container';
import {DeckContainer} from './containers/deck/deck.container';


const routes: Routes = [
  {
    path: '',
    component: TmpWelcomeContainer
  },
  {
    path: 'deck',
    component: DeckContainer
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
