import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  OnInit,
  Output,
} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {Subscription} from '../../../types/subscription.interface';

@Component({
  selector: 'amk-subscription-form',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./subscription-form.component.scss'],
  template: `
    <form [formGroup]="potentialUserForm" (ngSubmit)="onSubmit()">
      <mat-form-field appearance="outline">
        <mat-label>Title:</mat-label>
        <mat-select formControlName="title">
          <mat-option *ngFor="let title of titles" [value]="title">
            {{ title }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>First name</mat-label>
        <input formControlName="firstName" matInput>
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>Last name</mat-label>
        <input formControlName="lastName" matInput>
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>Country</mat-label>
        <mat-select formControlName="country">
          <mat-option *ngFor="let country of countries" [value]="country">
            {{ country }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>Email</mat-label>
        <input matInput formControlName="email"
               placeholder="Ex. jean.mouloud@email.com">
        <mat-error
          *ngIf="emailError.hasError('email') && !emailError.hasError('required')">
          Please enter a valid email address
        </mat-error>
        <mat-error *ngIf="emailError.hasError('required')">
          Email is required
        </mat-error>
      </mat-form-field>

    </form>
    <button mat-raised-button color="primary" (click)="onSubmit()">Submit</button>
    <button mat-raised-button (click)="onReset()">Reset</button>
  `
})
export class SubscriptionFormComponent implements OnInit {
  @Output() formData: EventEmitter<Subscription> = new EventEmitter<Subscription>();
  potentialUserForm: FormGroup;
  emailError: FormControl;
  titles = ['Mr', 'Mrs', 'Ms'];
  countries = ['Other', 'Algeria', 'Belgium', 'France', 'Italy', 'Morocco', 'Spain', 'Tunuisia'];

  constructor(private formBuilder: FormBuilder) {
  }

  ngOnInit(): void {
    this.potentialUserForm = this.formBuilder.group({
      title: ['', Validators.required],
      firstName: ['', [Validators.required]],
      lastName: [''],
      country: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]]
    });

    this.emailError = this.potentialUserForm.controls.email as FormControl;
  }

  onSubmit(): void {
    if (this.potentialUserForm.invalid) {
      return;
    }

    this.formData.emit({
      ...this.potentialUserForm.value,
      subscriptionIsoTime: (new Date()).toISOString()
    });
  }

  onReset() {
    this.potentialUserForm.reset();
  }
}
