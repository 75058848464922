/**
 * Convert snake_case to camelCase
 * @param input: string to process
 */
export const snakeToCamel = (input: string) =>
  input.replace(/_([a-zA-Z0-9])/g, (all, letter) => letter.toUpperCase());

/**
 * Convert camelCase to snake_case
 * @param input: string to process
 */
export const camelToSnake = (input: string) =>
  input.replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`);
