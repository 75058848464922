import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';

import {AppRoutingModule} from './app-routing.module';
import {AppContainer} from './containers/app/app.container';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {MaterialModule} from '../material/material.module';
import {AppSandbox} from './app.sandbox';
import {DeckContainer} from './containers/deck/deck.container';
import {DeckService, LaunchReminderService} from './services';
import {HttpClientModule} from '@angular/common/http';
import {TmpWelcomeContainer} from './containers/tmp-welcome/tmp-welcome.container';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {SubscriptionFormComponent} from './containers/tmp-welcome/subscription-form/subscription-form.component';
import {NgxSpinnerModule} from 'ngx-spinner';
import {CardComponent} from './shared-components/card/card.component';
import {ServiceWorkerModule} from '@angular/service-worker';
import {environment} from '../../environments/environment';

@NgModule({
  declarations: [
    AppContainer,
    DeckContainer,
    TmpWelcomeContainer,
    SubscriptionFormComponent,
    CardComponent,
  ],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MaterialModule,
    HttpClientModule,
    NgxSpinnerModule,
    ServiceWorkerModule.register('ngsw-worker.js', {enabled: environment.production}),
  ],
  providers: [
    AppSandbox,
    DeckService,
    LaunchReminderService,
  ],
  bootstrap: [AppContainer]
})
export class AppModule {
}
